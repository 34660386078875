import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Summary on the How to Build a Startup Course on Udacity",
  "date": "2015-08-10T01:39:49-05:00"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is recap on the `}<a parentName="p" {...{
        "href": "https://www.udacity.com/course/how-to-build-a-startup--ep245"
      }}>{`How to Build a Startup`}</a>{` course on Udacity. The idea of this course is to cover how to rapidly develop and validate ideas from customers and marketplaces as many startups failed by not validating their ideas in their early stages.`}</p>
    <p>{`The course introduces Business Model Canvas, which covers 9 essential components:`}</p>
    <ol>
      <li parentName="ol">{`Value Proposition`}</li>
      <li parentName="ol">{`Customer Segments`}</li>
      <li parentName="ol">{`Channels`}</li>
      <li parentName="ol">{`Customer Relationships`}</li>
      <li parentName="ol">{`Revenue Models`}</li>
      <li parentName="ol">{`Partners`}</li>
      <li parentName="ol">{`Key Resources`}</li>
      <li parentName="ol">{`Key Activities`}</li>
      <li parentName="ol">{`Costs`}</li>
    </ol>
    <h4>{`Value Proposition`}</h4>
    <p>{`What are we building? for who? What does it solve for a targeted group of people? The key point is to get out of the building and validate with real people, whether it satisfy the customer’s needs. As the result, what is the minimum viable product (MVP) once we acquire our understanding.`}</p>
    <h4>{`Customer Segments`}</h4>
    <p>{`Customer Segment is about who we are building for, what do they want to achieve, what pain do they want to solve and what gain do they want to see. The goal is to start a series of hypotheses, then validate our hypotheses with the real people and see whether we are right or wrong.`}</p>
    <h4>{`Channels`}</h4>
    <p>{`Is our product physical (e.g. can of food, Books) or virtual (e.g. insurance, stocks)? How does our product goes from our company to our customers? Is it through Physical Channel (Brick-and-mortar store) or Virtual Channel (E-commerce store, social network)`}</p>
    <h4>{`Customer Relationships`}</h4>
    <p>{`How to get, keep and grow customers.`}</p>
    <p><img parentName="p" {...{
        "src": "https://s3-us-west-1.amazonaws.com/web.zana.io/media/lessons__resource__actual_file/Screen_Shot_2014-08-25_at_12.36.47_PM.png",
        "alt": null
      }}></img></p>
    <ul>
      <li parentName="ul">{`Get - How to acquire customers and get them to purchase our products.`}</li>
      <li parentName="ul">{`Keep - How to keep existing customers and avoid losing customers we spent acquiring.`}</li>
      <li parentName="ul">{`Grow - How to sell existing customers more products`}</li>
    </ul>
    <h4>{`Revenue Streams`}</h4>
    <p>{`Types of revenue streams:`}</p>
    <ul>
      <li parentName="ul">{`Direct`}</li>
      <li parentName="ul">{`Ancillary`}</li>
      <li parentName="ul">{`Asset`}</li>
      <li parentName="ul">{`Usage`}</li>
      <li parentName="ul">{`Subscriptions`}</li>
    </ul>
    <h4>{`Partners`}</h4>
    <p>{`Partners are other companies that provide us resources, activities and other things that help make our company success. They could be partners or suppliers or both.`}</p>
    <p>{`Types of partners:`}</p>
    <ul>
      <li parentName="ul">{`Strategic alliances`}</li>
      <li parentName="ul">{`Joint Ventures`}</li>
      <li parentName="ul">{`Coopetitions`}</li>
      <li parentName="ul">{`Traffic Partners`}</li>
      <li parentName="ul">{`Joint Business Development`}</li>
      <li parentName="ul">{`Suppliers`}</li>
    </ul>
    <h4>{`Key Resources`}</h4>
    <p>{`What are the most important assets that make our business model work? E.g.`}</p>
    <ul>
      <li parentName="ul">{`Financial Resources - How to we get the startup off the ground? Where do we get our capital to keep the company running? Friends and family members? Angel Investors? Crowdfunding?`}</li>
      <li parentName="ul">{`Human Resources - Finding qualified employees, finding mentors/coaches/teachers.`}</li>
      <li parentName="ul">{`Physical Resources - e.g. Company Facilities/Offices, where do we establish headquarters at? Where are we doing most of our work at? Where do we get our supplies?`}</li>
      <li parentName="ul">{`Intellectual Resources - Intellectual properties (IP) like trademarks (logo/slogan), copyright (creative/authored work), trade secrets (e.g. Coca Cola recipe, customer list), patent (inventions).`}</li>
    </ul>
    <h4>{`Key Activities`}</h4>
    <p>{`Key activities that our business model requires`}</p>
    <h4>{`Cost`}</h4>
    <p>{`What are the most important cost`}</p>
    <ul>
      <li parentName="ul">{`Fixed Cost - e.g. Building cost, employee cost from month to month.`}</li>
      <li parentName="ul">{`Variable Cost - e.g. resources / activities`}</li>
    </ul>
    <h4>{`Recommended Readings`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.businessmodelgeneration.com/book"
        }}>{`Business Model Generation Book`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.stevenblank.com/startup_index_qty.html"
        }}>{`Startup OwnerΓÇÖs Manual Book`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      